// extracted by mini-css-extract-plugin
export var autoSliderNav = "styles-module--autoSliderNav--cc655";
export var autoSliderNavItem = "styles-module--autoSliderNavItem--50f67";
export var autoSliderNavItem_active = "styles-module--autoSliderNavItem_active--818fb";
export var autoSliderNavItem_done = "styles-module--autoSliderNavItem_done--2dd6e";
export var column = "styles-module--column--ea087";
export var columnHeading = "styles-module--columnHeading--1d592";
export var columns = "styles-module--columns--5dfd5";
export var link = "styles-module--link--6490a";
export var stepNumber = "styles-module--stepNumber--defb3";
export var subtitle = "styles-module--subtitle--c3d02";
export var text = "styles-module--text--b3233";
export var threeColumnsDefault = "styles-module--threeColumnsDefault--91576";
export var threeColumnsDefault_alternative = "styles-module--threeColumnsDefault_alternative--f2728";
export var title = "styles-module--title--44e85";